<template>
  <div v-if="readonly" :class="css">
    <slot name="readonly-value" v-bind="{ hasInnerValue, readonly, css, showValue, hideLabel, labelWithSuffix, readonlyValue, labelCss, valueCss }">
      <label v-if="!hideLabel" :class="labelCss">{{labelWithSuffix}}</label>
      <p v-if="showValue" :class="valueCss">{{ readonlyValue }}</p>
    </slot>
  </div>
  <div v-else>
    <ValidationProvider :vid="vid" :name="name" :rules="rules" ref="valprov" :disabled="disableValidation">
      <b-form-group slot-scope="{ invalid, valid, validated, errors }" :disabled="readonly" :label="labelWithSuffix" :label-sr-only="hideLabel" :label-for="$attrs.id" :description="description">
        <b-form-input v-model="innerValue" :id="$attrs.id" :name="name" :state="disableValidation ? null : (validated ? valid : null)" :type="type" @input="onInput($event, invalid)" :placeholder="placeholder" :class="css" v-bind="{ min, max }">
        </b-form-input>
        <b-form-invalid-feedback><span v-for="(error, idx) in errors" :key="idx">{{error}}</span></b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)
import s from 'underscore.string'
import '@/shared/validations.js'

export default {
  inheritAttrs: false,
  props: {
    vid: {
      type: String
    },
    rules: {
      type: Object|String,
      default: ''
    },
    disableValidation: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    name: {
      type: String
    },
    description: {
      type: String
    },
    css: {
      type: String,
      default: null
    },
    labelCss: {
      type: String,
      default: 'col-form-label'
    },
    valueCss: {
      type: String,
      default: 'font-bold'
    },
    // must be included in props
    value: {
      type: null
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    triggerInnerValueChanges: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    innerValue: '',
    forcedState: null
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (this.triggerInnerValueChanges) {
        this.$emit('input', newVal)
      }
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
      /*
      if (this.triggerInnerValueChanges) {
        this.innerValue = newVal
      }*/
    }
  },
  created() {
    if (this.value != null) {
      this.innerValue = this.value
    }
  },
  computed: {
    hasInnerValue() {
      return this.innerValue != null
    },
    labelWithSuffix() {
      if (!this.readonly) {
        let rulesArray = this.rules
        if (typeof(this.rules) == 'object') {
          rulesArray = Object.entries(this.rules)
        }
        if (this.localizedLabel && rulesArray && rulesArray.includes('required')) {
          return this.localizedLabel + '*'
        }
      }
      return this.localizedLabel
    },
    min() {
      if (this.$options.name == 'Percentage') {
        return 0
      }
    },
    max() {
      if (this.$options.name == 'Percentage') {
        return 100
      }
    },
    labelValue() {
      if (this.hideLabel) {
        return
      }
      if (this.localizedLabel) {
        return this.localizedLabel
      }
      return s(this.field).humanize().value()
    },
    localizedLabel() {
      if (this.$i18n) {
        return this.$t(this.name)
      }
      if (this.label) {
        return this.label
      }
      return this.name
    },
    showValue() {
      if (this.hasInnerValue) {
        return this.innerValue.toString().length > 0
      }
    },
    readonlyValue() {
      return this.innerValue
    }
  },
  methods: {
    onInput(e, valid) {
      if (valid) {
        this.$parent.$emit('input', e)
      }
    }
  }
}
</script>
